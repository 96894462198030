import { Row } from 'react-bootstrap';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Link from 'ui-kit/link/link';
import Button from 'ui-kit/button/button';
import './auth-mobile-nav.style.scss';
import { AuthMobileNavProps } from './auth-mobile-nav.props';

const AuthMobileNav = ({ onBackButtonClick, hideBackButton = false }: AuthMobileNavProps) => {
    const { t } = useTranslation();
    const justifyContentClassName = hideBackButton ? 'justify-content-end' : 'justify-content-between';

    return (
        <Row className={`d-flex flex-row auth-mobile-nav-container ${justifyContentClassName}`}>
            {!hideBackButton && (
                <Button
                    className="return-button"
                    type="button"
                    chevron="left"
                    variant="ghost"
                    onClick={onBackButtonClick}
                    label={t('pages.profile.messages.labels.back')}
                />
            )}
            <Link
                className="d-inline-block"
                to="/sign-in"
                label={t('pages.profile.messages.labels.cancel')}
                variant="primary"
                dataGALocation="loginIssues"
            />
        </Row>
    );
};

export default AuthMobileNav;
