import * as yup from 'yup';

export const REGISTRATION_SCHEMA = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    dobMonth: yup.string().required(),
    dobDay: yup.string().required(),
    dobYear: yup
        .string()
        .required()
        .test('dobYear', 'Future', function (value) {
            if (this.parent.dobMonth && this.parent.dobDay && value) {
                return Date.parse(`${this.parent.dobMonth} ${this.parent.dobDay}, ${value}`) <= Date.now();
            } else {
                return true;
            }
        }),
    insuranceChoice: yup.string().required(),
    memberId: yup.string().when('insuranceChoice', {
        is: 'insurance',
        then: yup.string().required(),
        otherwise: yup.string()
    }),
    address1: yup.string().when('insuranceChoice', {
        is: 'discount',
        then: yup.string().required(),
        otherwise: yup.string()
    }),
    address2: yup.string(),
    city: yup.string().when('insuranceChoice', {
        is: 'discount',
        then: yup.string().required(),
        otherwise: yup.string()
    }),
    state: yup.string().when('insuranceChoice', {
        is: 'discount',
        then: yup.string().required(),
        otherwise: yup.string()
    }),
    zipcode: yup.string().when('insuranceChoice', {
        is: 'discount',
        then: yup.string().required(),
        otherwise: yup.string()
    })
});

export const REGISTRATION_SECURITY_QUESTION_ANSWER_SCHEMA = yup.object().shape({
    question1: yup.string().required(),
    question2: yup.string().required(),
    question3: yup.string().required(),
    answer1: yup.string().required(),
    answer2: yup.string().required(),
    answer3: yup.string().required()
});
