import React, { useMemo, useContext, useEffect } from 'react';
import { ALLOW_BIRDIDISCOUNT_REG } from 'gatsby-env-variables';

import classNames from 'classnames';
import { Row, Col, Accordion } from 'react-bootstrap';

import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { REGISTRATION_SCHEMA } from 'schema/registration.schema';
import Button from 'ui-kit/button/button';
import Text from 'ui-kit/text/text';
import Tooltip from 'ui-kit/tooltip/tooltip';
import WorkflowSteps from 'ui-kit/workflow-steps/workflow-steps';
import { AddressFormFields, AddressFormSchema } from 'components/add-address-form/AddAddressForm';

import { getDaysOfMonthOptions, getYearOptions, monthOptions } from 'const/options';
import FormSelect from 'ui-kit/form-select/form-select';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

import './registration-form.style.scss';
import { TrackSignupFlowInteraction } from 'util/google_optimize/optimize_helper';
import { useGlobalLink } from 'hooks/useGlobalLink';
import Link from 'ui-kit/link/link';
import { graphql, useStaticQuery } from 'gatsby';

// TODO (TECH DEBT): we should move this back into types/registration
export interface RegistrationFormValues extends AddressFormSchema {
    firstName: string;
    lastName: string;
    dobMonth: string;
    dobDay: string;
    dobYear: string;
    memberId: string;
    insuranceChoice: string;
}

export interface RegistrationFormProps {
    className?: string;
    onSubmit: (
        values: Partial<RegistrationFormValues>,
        helpers: FormikHelpers<Partial<RegistrationFormValues>>
    ) => void;
}

const RegistrationForm = ({ className, onSubmit }: RegistrationFormProps) => {
    const { t } = useTranslation();

    const classes = classNames('registration-form', className);
    const minYear = new Date().getFullYear() - 120;
    const yearOptions = useMemo(() => getYearOptions({ min: minYear }), [minYear]);
    const dayOptions = useMemo(() => getDaysOfMonthOptions(), []);
    const formName = 'Registration - Info';
    const globalLink = useGlobalLink();
    const partnerLinks = useStaticQuery(graphql`
        {
            allNodeApplicationPage(
                filter: {
                    path: { alias: { eq: "/get-started" } }
                    relationships: { field_block: { internal: { type: { eq: "block_content__cta_redirect" } } } }
                }
            ) {
                nodes {
                    path {
                        alias
                    }
                    relationships {
                        field_block {
                            internal {
                                type
                            }
                            body {
                                processed
                            }
                            field_block_label
                            field_cta_link {
                                title
                                uri
                            }
                            field_link_style
                        }
                    }
                }
            }
        }
    `);

    const ctaLinks = partnerLinks.allNodeApplicationPage.nodes[0].relationships.field_block;

    useEffect(() => {
        globalLink.setFormName(formName);
        globalLink.setFlowName('Signup');
        globalLink.setStepName(t('registration.profileTitle'));
    }, [globalLink, t]);

    function getErrorFromStatus(status: string): String {
        let errorMessage = '';
        switch (status) {
            case 'Patient is already registered.':
                errorMessage = t('registration.errors.alreadyRegistered');
                break;
            default:
                errorMessage = t('registration.errors.notEligable');
                break;
        }

        return errorMessage;
    }

    function ContextAwareToggle({
        children,
        fieldName,
        label,
        eventKey,
        callback,
        toolTip
    }: {
        children?: object;
        fieldName: string;
        label: string;
        eventKey: string;
        callback?: any;
        toolTip?: string;
    }) {
        const currentEventKey = useContext(AccordionContext);
        const toggleAccordion = useAccordionToggle(eventKey, () => callback && callback(eventKey));
        const decoratedOnClick = (e: any) => {
            if (eventKey !== currentEventKey) {
                toggleAccordion(e);
            }
        };

        return (
            <Row className="radio-toggle">
                <Col>
                    <label className="radio-toggle--label border rounded">
                        <Field
                            type="radio"
                            name={fieldName}
                            value={eventKey}
                            onClick={decoratedOnClick}
                            className="radio-toggle--input mr-2"
                        />
                        {label}
                    </label>
                    {children}
                    {toolTip && (
                        <div className="tooltip-wrapper">
                            <Tooltip tip={toolTip} id={'contextAwareTip'} />
                        </div>
                    )}
                </Col>
            </Row>
        );
    }

    return (
        <div className={classes}>
            <Row>
                <Col>
                    <Formik
                        onSubmit={onSubmit}
                        validationSchema={REGISTRATION_SCHEMA}
                        initialValues={{
                            firstName: undefined,
                            lastName: undefined,
                            dobMonth: undefined,
                            dobDay: undefined,
                            dobYear: undefined,
                            memberId: undefined,
                            insuranceChoice: ALLOW_BIRDIDISCOUNT_REG ? undefined : 'insurance',
                            address1: undefined,
                            address2: undefined,
                            city: undefined,
                            state: undefined,
                            zipcode: undefined
                        }}
                    >
                        {({
                            values,
                            touched,
                            errors,
                            status,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting
                        }) => (
                            <Form
                                id="registration-form"
                                data-ga-form-name={formName}
                                onSubmit={handleSubmit}
                                autoComplete="off"
                            >
                                {ALLOW_BIRDIDISCOUNT_REG && (
                                    <div className="form-instructions">{t('registration.personalInfoHeading')}</div>
                                )}
                                <div className={`${ALLOW_BIRDIDISCOUNT_REG ? 'mt-3 ml-3' : ''}`}>
                                    <Row>
                                        <Col sm={12} lg={6}>
                                            <Text
                                                name="firstName"
                                                label={t('profile.firstName')}
                                                onChange={handleChange}
                                                errors={errors?.firstName ? t(`profile.errors.firstName`) : undefined}
                                                touched={touched.firstName}
                                                value={values?.firstName}
                                                onFocus={() => globalLink.handleFieldFocus(t('profile.firstName'))}
                                            />
                                        </Col>
                                        <Col sm={12} lg={6}>
                                            <Text
                                                name="lastName"
                                                label={t('profile.lastName')}
                                                onChange={handleChange}
                                                errors={errors?.lastName ? t(`profile.errors.lastName`) : undefined}
                                                touched={touched.lastName}
                                                value={values?.lastName}
                                                onFocus={() => globalLink.handleFieldFocus(t('profile.lastName'))}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} lg={4}>
                                            <Field
                                                id="profile-setup-dob-month"
                                                name="dobMonth"
                                                options={monthOptions}
                                                component={FormSelect}
                                                placeholder={t('profile.dobMonth')}
                                                errors={errors?.dobMonth ? t(`profile.errors.dobMonth`) : undefined}
                                                touched={touched.dobMonth}
                                                onFocus={() => globalLink.handleFieldFocus(t('profile.dobMonth'))}
                                            />
                                        </Col>
                                        <Col xs={12} lg={4}>
                                            <Field
                                                id="profile-setup-dob-day"
                                                name="dobDay"
                                                options={dayOptions}
                                                component={FormSelect}
                                                placeholder={t('profile.dobDay')}
                                                errors={errors?.dobDay ? t(`profile.errors.dobDay`) : undefined}
                                                touched={touched.dobDay}
                                                onFocus={() => globalLink.handleFieldFocus(t('profile.dobDay'))}
                                            />
                                        </Col>
                                        <Col xs={12} lg={4}>
                                            <Field
                                                id="profile-setup-dob-year"
                                                name="dobYear"
                                                options={yearOptions}
                                                component={FormSelect}
                                                placeholder={t('profile.dobYear')}
                                                errors={
                                                    errors?.dobYear
                                                        ? errors?.dobYear === 'Future'
                                                            ? t(`profile.errors.dobYearFuture`)
                                                            : t(`profile.errors.dobYear`)
                                                        : undefined
                                                }
                                                touched={touched.dobYear}
                                                onFocus={() => globalLink.handleFieldFocus(t('profile.dobYear'))}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                {ALLOW_BIRDIDISCOUNT_REG && (
                                    <>
                                        <div className="form-instructions">
                                            {t('registration.insuranceInfoHeading')}
                                        </div>
                                        <div className="mt-3 ml-3">
                                            <Accordion defaultActiveKey="-1">
                                                <div className="accordionItem">
                                                    <div className="accordionItem--header">
                                                        <ContextAwareToggle
                                                            fieldName="insuranceChoice"
                                                            eventKey="insurance"
                                                            label={t('registration.insuranceChoices.withInsuranceID')}
                                                            callback={() => {
                                                                TrackSignupFlowInteraction(
                                                                    t('registration.insuranceChoices.withInsuranceID')
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    <Accordion.Collapse eventKey="insurance">
                                                        <Row>
                                                            <Col>
                                                                <Text
                                                                    name="memberId"
                                                                    label={t('pages.profile.insurance.memberID')}
                                                                    onChange={handleChange}
                                                                    errors={
                                                                        errors?.memberId
                                                                            ? t('forms.errorMessages.requiredField', {
                                                                                  label: t(
                                                                                      'pages.profile.insurance.memberID'
                                                                                  )
                                                                              })
                                                                            : undefined
                                                                    }
                                                                    touched={touched.memberId}
                                                                    defaultValue={values.memberId}
                                                                    className={'mb-3'}
                                                                    onFocus={() =>
                                                                        globalLink.handleFieldFocus(
                                                                            t('pages.profile.insurance.memberID')
                                                                        )
                                                                    }
                                                                />
                                                                <div className="tooltip-wrapper">
                                                                    <Tooltip
                                                                        id={'memberIDToolTip'}
                                                                        tip={t(
                                                                            'pages.profile.insurance.memberIDToolTip'
                                                                        )}
                                                                    />
                                                                </div>
                                                                {!!status && (
                                                                    <div className="text has-errors">
                                                                        <div className="text-errors">
                                                                            {getErrorFromStatus(status)}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </Accordion.Collapse>
                                                </div>
                                                <div className="accordionItem">
                                                    <div className="accordionItem--header">
                                                        <ContextAwareToggle
                                                            fieldName="insuranceChoice"
                                                            eventKey="discount"
                                                            label={t(
                                                                'registration.insuranceChoices.withoutInsuranceID'
                                                            )}
                                                            callback={() => {
                                                                TrackSignupFlowInteraction(
                                                                    t(
                                                                        'registration.insuranceChoices.withoutInsuranceID'
                                                                    )
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    <Accordion.Collapse eventKey="discount" className="mt-4">
                                                        <AddressFormFields
                                                            t={t}
                                                            localesBaseKey={'registration'}
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                            values={values}
                                                            errors={errors}
                                                            touched={touched}
                                                            hideAddressType={true}
                                                            trackFormInteraction={true}
                                                        />
                                                    </Accordion.Collapse>
                                                </div>
                                            </Accordion>
                                        </div>
                                    </>
                                )}
                                {!ALLOW_BIRDIDISCOUNT_REG && (
                                    <Row>
                                        <Col>
                                            <Text
                                                name="memberId"
                                                label={t('pages.profile.insurance.memberID')}
                                                onChange={handleChange}
                                                errors={
                                                    errors?.memberId
                                                        ? t('forms.errorMessages.requiredField', {
                                                              label: t('pages.profile.insurance.memberID')
                                                          })
                                                        : undefined
                                                }
                                                touched={touched.memberId}
                                                defaultValue={values.memberId}
                                                className={'mb-3'}
                                                onFocus={() =>
                                                    globalLink.handleFieldFocus(t('pages.profile.insurance.memberID'))
                                                }
                                            />
                                            <div className="tooltip-wrapper">
                                                <Tooltip
                                                    id={'memberIDToolTip'}
                                                    tip={t('pages.profile.insurance.memberIDToolTip')}
                                                />
                                            </div>
                                            {!!status && (
                                                <div className="text has-errors">
                                                    <div className="text-errors">{getErrorFromStatus(status)}</div>
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <Col className="d-flex flex-column align-items-center my-4">
                                        <p className="other-options">Or</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex flex-column align-items-center">
                                        <Row className="mb-4 login-options-container">
                                            {ctaLinks.field_cta_link.map((link: any) => (
                                                <>
                                                    <Col
                                                        key={link.uri}
                                                        xs={12}
                                                        sm={4}
                                                        className="d-flex flex-column link-description"
                                                    >
                                                        <p className="font-weight-bold mb-1">
                                                            {link.title.replace('Sign in to', '').trim()} customer?
                                                        </p>
                                                        <Link
                                                            to={link.uri}
                                                            label={t('signIn.labels.signInToAccount')}
                                                            variant="underline"
                                                            external={false}
                                                            dataGALocation={formName}
                                                        />
                                                    </Col>
                                                    <div className="divider" />
                                                </>
                                            ))}
                                            <Col xs={12} sm={4} className="d-flex flex-row link-description">
                                                <Link
                                                    className="font-weight-bold m-0"
                                                    to={'/sign-in'}
                                                    label={t('registration.signIn')}
                                                    variant="underline"
                                                    dataGALocation={formName}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex flex-column align-items-center mt-4">
                                        <Button
                                            async
                                            className="sm-full"
                                            disabled={isSubmitting}
                                            label={t('registration.submit')}
                                            variant="primary"
                                            type="submit"
                                            dataGAFormName={formName}
                                            dataGAFormStep="Step1"
                                            dataGAFormStepName="Account"
                                            isBusy={isSubmitting}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex flex-column align-items-center mt-3">
                    <WorkflowSteps id="registration-page" activeStepName="Account" activeStep={1} numberOfSteps={2} />
                </Col>
            </Row>
        </div>
    );
};

export default RegistrationForm;
