import React from 'react';
import classNames from 'classnames';
import { Container, Row, Col } from 'react-bootstrap';

import { WorkflowLayoutFormWrapperProps } from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.props';

import './workflow-layout-form-wrapper.style.scss';

const WorkflowLayoutFormWrapper = ({
    banner,
    children,
    className,
    eyebrowText,
    title,
    icon,
    nav
}: WorkflowLayoutFormWrapperProps) => {
    const classes = classNames('workflow-layout-form-wrapper', className);
    const colClasses = classNames(
        'workflow-layout-form-wrapper-header d-flex flex-column justify-content-center align-items-center',
        banner ? 'with-banner' : ''
    );

    return (
        <div className={classes}>
            <Container fluid>
                {banner && (
                    <Row>
                        <Col className="workflow-layout-form-wrapper-banner">{banner}</Col>
                    </Row>
                )}
                {nav && (
                    <Row className="d-md-none">
                        <Col className="workflow-layout-nav-wrapper">{nav}</Col>
                    </Row>
                )}
                <Row>
                    <Col className={colClasses}>
                        {icon}
                        {eyebrowText && <div className="h5">{eyebrowText}</div>}
                        <h1 className="h2">{title}</h1>
                        <div className="spacer" />
                    </Col>
                </Row>
                <Row>
                    <Col className="workflow-layout-form-wrapper-content">{children}</Col>
                </Row>
            </Container>
        </div>
    );
};

export default WorkflowLayoutFormWrapper;
