import { ReactElement } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useDispatch } from 'react-redux';
import { graphql } from 'gatsby';

import { AddressParts } from 'state/usps/usps.reducers';
import { useAddressVerification } from 'hooks/useAddressVerification';
import { AddressValidateResponse } from 'util/usps';
import { AddressVerificationAPIFailureModalContent } from 'components/add-address-form/AddAddressForm';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';

import { accountRegisterRoutine } from 'state/account/account.routines';

import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

import RegistrationForm, { RegistrationFormValues } from 'components/registration-form/registration-form.component';
import { FormikHelpers } from 'formik';
import AddressVerificationModal from 'components/address-verification-modal/AddressVerificationModal.component';
import AuthMobileNav from 'components/auth-mobile-nav';

const GetStarted = ({ location, data }: { location: any; data: GatsbyTypes.GetStartedDataQuery }): ReactElement => {
    const { verifyAddress } = useAddressVerification();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onSubmit = (
        values: Partial<RegistrationFormValues>,
        helpers: FormikHelpers<Partial<RegistrationFormValues>>
    ) => {
        if (values.insuranceChoice === 'discount') {
            const onVerificatonAddressChoice = (address: AddressParts) => {
                dispatch(closeModal({}));
                if (address) {
                    let valuesWithChosenAddress = {
                        ...values,
                        ...address
                    };
                    dispatch(
                        accountRegisterRoutine.trigger({
                            formValues: {
                                ...valuesWithChosenAddress,
                                firstName: valuesWithChosenAddress.firstName?.trim(),
                                lastName: valuesWithChosenAddress.lastName?.trim()
                            },
                            formHelpers: helpers
                        })
                    );
                }
            };

            // Address needs to be validated prior to dispatching `accountRegisterRoutine`
            const address: AddressParts = {
                street1: values.address1 ? values.address1 : '',
                street2: values.address2,
                city: values.city ? values.city : '',
                state: values.state ? values.state : '',
                zip: values.zipcode ? values.zipcode : ''
            };
            verifyAddress({
                address,
                onSuccess: (validationResponse: AddressValidateResponse) => {
                    if (validationResponse.responseCode === 'suggested') {
                        dispatch(
                            openModal({
                                showClose: false,
                                backdrop: 'static',
                                className: 'address-verification-modal',
                                bodyContent: (
                                    <AddressVerificationModal
                                        originalAddress={validationResponse.currentAddress}
                                        correctedAddress={validationResponse.updatedAddress}
                                        onSuccess={onVerificatonAddressChoice}
                                    />
                                ),
                                ctas: [
                                    {
                                        label: t('modals.addressVerification.cancel'),
                                        variant: 'text-blue',
                                        onClick: () => {
                                            helpers.setSubmitting(false);
                                            dispatch(closeModal({}));
                                        },
                                        dataGALocation: 'AddressVerificationForm'
                                    }
                                ]
                            })
                        );
                    } else {
                        dispatch(
                            accountRegisterRoutine.trigger({
                                formValues: {
                                    ...values,
                                    firstName: values.firstName?.trim(),
                                    lastName: values.lastName?.trim()
                                },
                                formHelpers: helpers
                            })
                        );
                    }
                },
                onFailure: (validationResponse: AddressValidateResponse) => {
                    // Reset the form to allow updates.
                    helpers.setSubmitting(false);
                    const errors = {};
                    if (validationResponse.responseMessage) {
                        const errorField = validationResponse.responseField
                            ? validationResponse.responseField?.toLowerCase()
                            : 'address1';

                        errors[errorField] = validationResponse.responseMessage;
                        helpers.setErrors(errors);
                    }
                    dispatch(
                        openModal({
                            showClose: true,
                            className: 'prescription-modal',
                            bodyContent: <AddressVerificationAPIFailureModalContent translation={t} />,
                            ctas: [
                                {
                                    label: t('modals.healthConditions.submit'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    },
                                    dataGALocation: 'AddressVerificationError'
                                }
                            ]
                        })
                    );
                }
            });
        } else {
            dispatch(
                accountRegisterRoutine.trigger({
                    formValues: {
                        ...values,
                        firstName: values.firstName?.trim(),
                        lastName: values.lastName?.trim()
                    },
                    formHelpers: helpers
                })
            );
        }
    };

    return (
        <WorkflowLayout
            backgroundImage={data.blueSkyBackground}
            useRoundedCorners={false}
            metaData={{ nodeTitle: t('registration.eyebrowText') }}
            anonymousOnly={true}
        >
            <WorkflowLayoutFormWrapper
                eyebrowText={t('registration.eyebrowText')}
                title={t('registration.profileTitle')}
                nav={<AuthMobileNav hideBackButton />}
            >
                <RegistrationForm onSubmit={onSubmit} />
            </WorkflowLayoutFormWrapper>
        </WorkflowLayout>
    );
};
export default GetStarted;

export const query = graphql`
    query GetStartedData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/feather-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
